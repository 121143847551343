import logo from './logo.svg';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import ReactRoutes from './routes';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAlertMessage } from './components/redux/slice/alertMessage';
import { TOKEN } from './components/redux/api/Constants';
import AlertPopUp from './components/redux/Common/AlertPopup/alertPopup';

function App() {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);

  const togglePopup = () => {
    dispatch(saveAlertMessage(null));
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  // For Custom Loader
  const customLoader = useSelector((state) => state.loaderNoti);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log(event);
      // Disable F12 and Ctrl + Shift + I (Developer Tools)
      if (
        event.key === "F12" || // F12 key
        (event.ctrlKey && event.shiftKey && event.key === "I") // Ctrl + Shift + I
      ) {
        event.preventDefault(); // Block the default action
      }
    };

    // window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener
    // return () => {
    //   window.removeEventListener("keydown", handleKeyDown);
    // };
  }, []);


  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent right-click context menu
  };

  useEffect(() => {
    if (customLoader == false) {
      setLoading(false);
    } else if (customLoader == true) {
      setLoading(true);
    }
  }, [customLoader]);

  useEffect(() => {
    let token = localStorage.getItem(TOKEN);
    if (!token) {
      navigate("/");
      return;
    }
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [isSomeQueryPending]);
  return (
    <div>
      {alertMessage && (
        <AlertPopUp alertMessage={alertMessage} togglePopup={togglePopup} />
      )}
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <Spinner
            animation="border"
            className="primary"
            togglePopup={togglePopup}
          />
        </div>
      )}
      <ReactRoutes />
    </div>
  );
}

export default App;
