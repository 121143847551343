import React from 'react'
import Topbar from '../Topbar/Topbar'
import TopbarTwo from '../Topbar/TopbarTwo'
import { Outlet } from 'react-router-dom'

const Layout = (routename) => {
    return (
        <div className="">
            <TopbarTwo/>
            <Outlet />
        </div>
    )
}

export default Layout