import React from 'react'
import { tick_icon } from '../../assets/imgs'
import { useNavigate } from 'react-router-dom'

const AlreadyAutopay = () => {
    const navigate = useNavigate();
    
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div>
                <div className='mt-4  d-flex ac-jc'>
                    <div className="inbox  ontario-box">
                        <p className=' f9 fs-sm-11 fs-md-13 fs-lg-15 fs-xl-16 sec  mb-0'> ONTARIO</p>
                        <h4 className='f10 fs-xs-20 fs-sm-22 fs-md-24 fs-lg-26 fs-xl-28 fs-xxl-30 mb-0 letter-spacing'> ABCD123</h4>
                    </div>
                </div>
                <div className='mt-1 d-flex ac-jc'>
                    <p onClick={()=>navigate("/licence-plate")} className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'> Change Plate</p>
                </div>
            </div>
            <div className='d-flex flex-column ac-jc'>
                <img src={tick_icon} className='tick-icon' alt="" />
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16  p-tag mb-0 mt-2'> Autopay is already on for your plate! Enjoy your day!</p>
            </div>
            <div className='d-flex ac-jc pb-3'>
                <button onClick={() => { navigate("/setup-autopay", { state: { name: "data" } }) }} className='inbox white f2 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer'>View more in App</button>
            </div>
        </div>
    )
}

export default AlreadyAutopay