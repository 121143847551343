import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { tick_icon } from '../../assets/imgs'

const OnRegistry = () => {
    const navigate = useNavigate()
    const [timeInMinutes, setTimeInMinutes] = useState(0)

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60)
        const mins = minutes % 60
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
    }

    const incrementTime = () => {
        setTimeInMinutes((prevTime) => prevTime + 1)
    }

    const decrementTime = () => {
        setTimeInMinutes((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    };
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div>
                <div className=' d-flex ac-jc'>
                    <div className="inbox  ontario-box">
                        <p className=' f9 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 sec  mb-0'> ONTARIO</p>
                        <h4 className='f10 fs-xs-20 fs-sm-22 fs-md-24 fs-lg-26 fs-xl-28 fs-xxl-30 mb-0 letter-spacing'> ABCD123</h4>
                    </div>
                </div>
                <div className='mt-1 d-flex ac-jc'>
                    <p onClick={()=>navigate("/licence-plate")} className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'> Change Plate</p>
                </div>
            </div>
            <div className='d-flex flex-column ac-jc '>
                <img src={tick_icon} className='tick-icon' alt="" />
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 w-70 text-center mb-0 mt-2'>Your car is registered for this parking lot. Enjoy your day! </p>
            </div>
            <div className='d-flex flex-column '>
                <button  className='inbox white pay-btn f5 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer d-flex ac-jc mt-2'>View more in App</button>
            </div>
        </div>
    )
}

export default OnRegistry