import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apple_icon } from '../../assets/imgs'

const PlateNotRead = () => {
    const navigate = useNavigate()
    const [timeInMinutes, setTimeInMinutes] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hour, setHour] = useState(0);


    const handleTime = (time, type) => {
        if (type == "dec") {
            if (time >= 0) {
                setMinutes(parseInt(time));
            } else {
                setHour(parseInt(hour) - 1);
                setMinutes(59);
            }
        } else {
            if (time < 60) {
                setMinutes(parseInt(time));
            }
            else {
                setHour(parseInt(hour + 1));
                setMinutes(0);
            }
        }

    }


    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div>
                <div className=' d-flex ac-jc'>
                    <div className="inbox  ontario-box">
                        <p className=' f9 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 sec  mb-0'> ONTARIO</p>
                        <h4 className='f10 fs-xs-20 fs-sm-22 fs-md-24 fs-lg-26 fs-xl-28 fs-xxl-30 mb-0 letter-spacing'> ABCD123</h4>
                    </div>
                </div>
                <div className='mt-1 d-flex ac-jc'>
                    <p onClick={() => navigate("/licence-plate")} className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'> Change Plate</p>
                </div>
            </div>
            <div className='text-center'>
                <h4 className='f3 fs-sm-12 fs-md-16 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0'>How long are you staying?</h4>
                <div className="my-2 d-flex ac-jc">
                    <button onClick={() => { handleTime(minutes - 1, "dec") }} className="plus-btn">-</button>
                    <div className='num-btn d-flex ac-jc gap-1'>
                        <div className=' d-flex ac-jc'>
                            <input
                                className="time-box cust-btn white text-center"
                                type="text"
                                value={hour}
                                maxLength={2}
                                onChange={(e) => {
                                    if (e.target.value <= 12) {
                                        setHour(e.target.value)
                                    }
                                }}
                            /> <p className='f2 fs-xs-14 fs-sm-14 fs-md-16 fs-lg-20 fs-xl-22 mb-0 '>h</p>
                        </div>
                        <div className=' d-flex ac-jc'>
                            <input
                                className="time-box cust-btn white text-center"
                                type="text"
                                value={minutes}
                                maxLength={2}
                                onChange={(e) => {
                                    if (e.target.value < 60) {
                                        setMinutes(e.target.value)
                                    }
                                }}

                            /> <p className='f2 fs-xs-14 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-20 mb-0 '>m</p>
                        </div>
                    </div>
                    <button onClick={() => { handleTime(minutes + 1, "inc") }} className="plus-btn">+</button>
                </div>
                <p className=' f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 mb-0 '>Amount Owned:<span className='white f6'> $3.40</span></p>
            </div>
            <div className='d-flex flex-column '>
                <button onClick={() => { navigate("/pay-now") }} className='confirm-btn  f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer'>Pay by Card</button>

                <button onClick={() => { navigate("/pay-now") }} className='confirm-btn bg-gray3 f5 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer d-flex ac-jc mt-2'><img src={apple_icon} className='apple-icon mx-1' alt="" /> Pay</button>

            </div>

        </div>
    )
}

export default PlateNotRead