import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/Topbar/Topbar";

const LicencePlate = () => {
  const navigate = useNavigate();
  const Length = 6;

  const [id, setId] = useState(new Array(Length).fill(""));

  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;

    const newId = [...id];
    newId[index] = value.substring(value.length - 1);
    setId(newId);

    if (value && index < Length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !id[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(0, 1);
  };
  return (
    <div className="dashboard">
      <Topbar />
      <div className="body-view text-center d-flex flex-column ac-jb">
        <div>
          <h4 className="f3 fs-sm-12 fs-md-16 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0">
            {" "}
            Input your license plate:
          </h4>
          <div className="w-100 mt-4">
            <div className="inbox d-flex rounded-4">
              {id.map((value, index) => (
                <div className="input-box" key={index}>
                  <input
                    type="text"
                    className="input-fld"
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onClick={() => handleClick(index)}
                    maxLength={1}
                    placeholder='A'
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/already-autopay", { state: { name: "data" } })}
            className="confirm-btn f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-22 mt-4"
          >
            Confirm
          </button>
        </div>
        <div className="w-100 d-flex ac-jc">
          <h4 className="f6 fs-sm-12 fs-md-16 fs-lg-16 fs-xl-18 fs-xxl-22 sec1 border-bot pointer">
            FAQ
          </h4>
        </div>
      </div>
    </div>
  )
}

export default LicencePlate
