import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { apple_icon, payment_icon } from "../../assets/imgs";

const PayBill = () => {
  const [checked, setChecked] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleClick = () => {
    setChecked(!checked);
    setShowBtn(!checked); // Show or hide the password field based on the checkbox state
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const newErrors = { ...errors };

    switch (fieldName) {
      case "cardNumber":
        if (!/^\d{16}$/.test(value)) {
          newErrors.cardNumber = "Card number must be 16 digits.";
        } else {
          delete newErrors.cardNumber;
        }
        break;
      case "expiry":
        if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
          newErrors.expiry = "Expiry must be in MM/YY format.";
        } else {
          delete newErrors.expiry;
        }
        break;
      case "cvc":
        if (!/^\d{3}$/.test(value)) {
          newErrors.cvc = "CVC must be 3 digits.";
        } else {
          delete newErrors.cvc;
        }
        break;
      case "postalCode":
        if (!/^\d{5}$/.test(value)) {
          newErrors.postalCode = "Postal code must be 5 digits.";
        } else {
          delete newErrors.postalCode;
        }
        break;
      case "nameOnCard":
        if (!value) {
          newErrors.nameOnCard = "Name on card is required.";
        } else {
          delete newErrors.nameOnCard;
        }
        break;
      case "password":
        if (!value || value.length < 6) {
          newErrors.password = "Password must be at least 6 characters.";
        } else {
          delete newErrors.password;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  //   const handleSubmit = () => {
  //     // if (Object.keys(errors).length === 0) {
  //     //   // Navigate to payment
  //     //   navigate('paybycard');
  //     // }

  //   };

  const isFormValid = () => {
    return Object.keys(errors).length === 0 && cardNumber && expiry && cvc && postalCode && nameOnCard && (!showBtn || password);
  };

  return (
    <div className="body-center container ">
      <div className=" d-flex flex-column ac-jb w-100">
        <div className="col-md-6">
          <button onClick={() => navigate("/setup-autopay")} className="cust-btn sec1 d-flex ac-js">
            <NavigateBeforeIcon />Back
          </button>
          <h4 className="f3 fs-sm-12 fs-md-16 fs-lg-21 fs-xl-23 fs-xxl-24 mt-3">Pay Bill</h4>
          <div className="d-flex ac-js mt-4">
            <img src={payment_icon} className="card-icon" alt="" />
            <input
              type="text"
              name="cardNumber"
              value={cardNumber}
              onChange={(e) => handleInputChange(e, setCardNumber)}
              className="input-get ms-2 w-100"
              placeholder="Card Number"
            />
          </div>
          {errors.cardNumber && <p className="error">{errors.cardNumber}</p>}

          <div className="d-flex ac-jb mt-3">
            <input
              type="text"
              name="expiry"
              value={expiry}
              onChange={(e) => handleInputChange(e, setExpiry)}
              className="input-get col-md-3 col-sm-3"
              placeholder="MM/YY"
            />
            {errors.expiry && <p className="error">{errors.expiry}</p>}

            <input
              type="text"
              name="cvc"
              value={cvc}
              onChange={(e) => handleInputChange(e, setCvc)}
              className="input-get col-md-2 col-sm-2"
              placeholder="CVC"
            />
            {errors.cvc && <p className="error">{errors.cvc}</p>}

            <input
              type="text"
              name="postalCode"
              value={postalCode}
              onChange={(e) => handleInputChange(e, setPostalCode)}
              className="input-get col-md-4 col-sm-3"
              placeholder="Postal Code"
            />
            {errors.postalCode && <p className="error">{errors.postalCode}</p>}
          </div>

          <div>
            <input
              type="text"
              name="nameOnCard"
              value={nameOnCard}
              onChange={(e) => handleInputChange(e, setNameOnCard)}
              className="input-get w-100 mt-3"
              placeholder="Name on Card"
            />
            {errors.nameOnCard && <p className="error">{errors.nameOnCard}</p>}

            <div className="d-flex ac-js mt-2">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleClick}
              />
              <p className="f1 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 ms-2 mb-0 ">
                Enable Auto-pay
              </p>
            </div>
          </div>

          {showBtn && (
            <div>
              <input
                type="text"
                name="password"
                value={password}
                onChange={(e) => handleInputChange(e, setPassword)}
                className="input-get mt-3 w-100"
                placeholder="Create password"
              />
              {errors.password && <p className="error">{errors.password}</p>}

              <p className="f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 ms-2 mb-0 mt-2 sec1">
                This password will let you log into the app/site to manage auto-pay, see parking history, and more!
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6 mt-3">
          {!showBtn && !checked && (
            <div>
              <p className="f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 ms-2 mb-0 mt-3 sec1">
                This lot uses license plate reading cameras. Setting up auto-pay will charge you when you leave and ensure you cannot get ticketed!
              </p>
            </div>
          )}
          <p className="f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 ms-2 mt-3 mb-0 sec1">
            <span className="white f5">$3.40 CAD</span> Revalie Ottawa, Ottawa ON
          </p>

          <p className="f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 ms-2 mb-0 sec1">
            <span className="white f5">$0.24 CAD</span> HST
          </p>
          <div className="d-flex ac-jc mt-3">
            <button
              // onClick={handleSubmit}
              className="confirm-btn f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 px-5 mb-0 mt-2 pointer"
              // disabled={!isFormValid()}
              onClick={() => { navigate('/payment-success') }}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayBill;
