
export const BASE_URL = "https://jayachandran-scripts.ninositsolution.com"; // live

export const URL = {
  LOGIN: "/crmapi/login ",
  LOGOUT: "/crmapi/logout",

  //ADMINS
  ADD_ADMIN: "/crmapi/admins",
  LIST_ADMIN: "/crmapi/admins",
  UPDATE_ADMIN: "/crmapi/admins/",
  VIEW_ADMIN: "/crmapi/admins/",
  ADMIN_STATUS: "/crmapi/admins/",

  //LOCATIONS
  LOCATIONS_LIST: "/crmapi/locations",
  LOCATIONS_ADD: "/crmapi/locations",
  LOCATION_DEVICES_ADD: "/crmapi/devices/add",
  LOCATION_DEVICES_UPDATE: "/crmapi/devices/update/",
  LOCATION_CAMERAS: "/crmapi/devices-view/",
  LOCATION_CAMERAS_ADD: "/crmapi/cameras/add",
  LOCATION_CAMERAS_VIEW: "/crmapi/cameras/show/",
  LOCATION_CAMERAS_UPDATE: "/crmapi/cameras/update/",
  LOCATION_CAMERAS_DELETE: "/crmapi/cameras/delete/",
  LOCATION_DELETE: "/crmapi/locations/",
  LOCATION_POINT: "/crmapi/locations",
  LOCATION_POINT_LIST: "/crmapi/locations/point/",
  LOCATION_VIEW: "/crmapi/locations/",
  LOCATION_EDIT: "/crmapi/locations/",

  //LOCATION CONFIG
  LOCATION_CONFIG: "/crmapi/device-configurations/list",
  LOCATION_CONFIG_ADD: "/crmapi/device-configurations/store",
  LOCATION_CONFIG_EDIT: "/crmapi/device-configurations/update ",
  LOCATION_CONFIG_DELETE: "/crmapi/device-configurations/delete",
  LOCATION_CONFIG_VIEW: "/crmapi/device-configurations/edit",

  // VIP MASTER LIST
  VIP_MASTER_LIST: "/crmapi/imagemasters/list",
  VIP_MASTER_STATUS: "/crmapi/imagemasters/",
  VIP_MASTER_DELETE: "/crmapi/imagemasters/",
  VIP_MASTER_ADD: "/crmapi/imagemasters",
  VIP_MASTER_UPDATE: "/crmapi/imagemasters/",
  VIP_MASTER_VIEW: "/crmapi/imagemasters/show/",
  VIP_MASTER_COMPLETE_LIST: "/crmapi/imagemasters/vip-list/1",
  VIP_LIST: "/crmapi/person-records/list",
  VIP_LIST_DATE_RANGE: "/crmapi/person-records/date-range",

  // PARKING MASTER LIST
  PARKING_MASTER_LIST: "/crmapi/vehicle-masters/list",
  PARKING_MASTER_STATUS: "/crmapi/vehiclemasters/",
  PARKING_MASTER_DELETE: "/crmapi/vehicle-masters/delete/",
  ADD_PARKING_MASTER: "/crmapi/vehicle-masters/add",
  UPDATE_PARKING_MASTER: "/crmapi/vehicle-masters/update/",
  PARKING_MASTER_VIEW: "/crmapi/imagemasters/show/",
  PARKING_MASTER_UPDATE: "/crmapi/vehicle-masters/update/",
  PARKING_LIST_TIMING_DATE_RANGE: "/crmapi/vehicle-records/timing-range",
  PARKING_LIST_LOGS_DATE_RANGE: "/crmapi/vehicle-records/date-range",

  // SUSPECT MASTER LIST
  SUSPECT_MASTER_LIST: "/crmapi/imagemasters/list",
  SUSPECT_MASTER_STATUS: "/crmapi/imagemasters/",
  SUSPECT_MASTER_DELETE: "/crmapi/imagemasters/",
  SUSPECT_MASTER_ADD: "/crmapi/imagemasters",
  SUSPECT_MASTER_VIEW: "/crmapi/imagemasters/show/",
  SUSPECT_MASTER_UPDATE: "/crmapi/imagemasters/",
  SUSPECT_LIST_RANGE: "/crmapi/person-records/date-range",

  // WAREHOUSE MASTER LIST
  WAREHOUSE_MASTER_PERSON: "/crmapi/imagemasters/list",
  WAREHOUSE_MASTER_PERSON_ADD: "/crmapi/imagemasters",
  WAREHOUSE_MASTER_PERSON_EDIT: "/crmapi/imagemasters/",
  WAREHOUSE_MASTER_VIEW: "/crmapi/imagemasters/show/",
  WAREHOUSE_MASTER_PERSON_DELETE: "/crmapi/imagemasters/",
  WAREHOUSE_MASTER_PERSON_STATUS: "/crmapi/imagemasters/",

  WAREHOUSE_VEHICLE_MASTER_LIST: "/crmapi/vehicle-masters/list",
  WAREHOUSE_MASTER_ADD: "/crmapi/vehicle-masters/add",
  WAREHOUSE_MASTER_UPDATE: "/crmapi/vehicle-masters/update/",
  WAREHOUSE_MASTER_STATUS: "/crmapi/vehiclemasters/",
  WAREHOUSE_MASTER_DELETE: "/crmapi/vehicle-masters/delete/",

  WAREHOUSE_LIST_LOGS: "/crmapi/vehicle-records/list",
  WAREHOUSE_LIST_TIMINGS: "/crmapi/vehicles-records/filter-by-settings",
  WAREHOUSE_VEHICLE_TIMING_RANGE: "/crmapi/vehicle-records/timing-range",
  WAREHOUSE_VEHICLE_LOGS_RANGE: "/crmapi/vehicle-records/date-range",

  WAREHOUSE_PERSON_LIST_LOGS: "/crmapi/person-records/list",
  WAREHOUSE_PERSON_LIST_TIMINGS: "/crmapi/person-records/filter-by-settings",
  WAREHOUSE_LIST_TIMING_RANGE: "/crmapi/person-records/timing-range",
  WAREHOUSE_LIST_LOGS_RANGE: "/crmapi/person-records/date-range",

  // PARKING TIMING LIST
  PARKING_LIST_TIMINGS: "/crmapi/vehicles-records/filter-by-settings",
  PARKING_LIST_LOGS: "/crmapi/vehicle-records/list",
  // PARKING LOGS LIST
  // VIP LIST
  // SUSPECT LIST
  SUSPECT_LIST: "/crmapi/person-records/list",
  // TRESPASSING LIST
  TRESPASSING_MASTER: "/crmapi/imagemasters/list",
  TRESPASSING_LIST: "/crmapi/person-records/filter-by-settings",
  TRESPASSING_MASTER_DELETE: "/crmapi/imagemasters/",
  TRESPASSING_MASTER_ADD: "/crmapi/imagemasters",
  TRESPASSING_MASTER_UPDATE: "/crmapi/imagemasters/",
  TRESPASSING_LIST_TIMING: "/crmapi/person-records/filter-by-settings",
  TRESPASSING_LIST_LOGS: "/crmapi/person-records/list",

  // BILLING COUNTER
  BILLING_MASTER_RECORDS: "/crmapi/counter-records/list",
  BILLING_MASTER_ADD: "/crmapi/counter-records",
  BILLING_MASTER_VIEW: "/crmapi/counter-records/show/",
  BILLING_MASTER_UPDATE: "/crmapi/counter-records/update/",
  BILLING_MASTER_SHOW: "/crmapi/counter-records/",
  BILLING_MASTER_DELETE: "/crmapi/counter-records/delete/",
  BILLING_LIST_RANGE: "/crmapi/counter-records/date-range",
  BILLING_LIST: "/crmapi/counter-records/list",
  // DEVICES
  DEVICE_LIST: "/crmapi/devices-list",
  SSH_STATUS: "/crmapi/change-device-ssh-status",
  // SETTINGS
  SETTING_LIST: "/crmapi/settings",
  SETTING_LIST_UPDATE: "/crmapi/settings/",

  // ROLES
  ROLES_LIST: "/crmapi/roles",
  ROLES_LIST_DELETE: "/crmapi/roles/delete/",
  ROLES_LIST_ADD: "/crmapi/roles/add",
  ROLES_LIST_EDIT: "/crmapi/roles/update/",
  ROLES_LIST_PERMISSIONS: "/crmapi/permissions-list",
  ROLES_LIST_VIEW: "/crmapi/roles/edit/",

  // Date Range
  DATE_RANGE: "/crmapi/person-records/date-range",
  TIMING_DATE_RANGE: "/crmapi/person-records/date-range",
  // Overall Crowd
  OVERALL_CROWD: "/crmapi/records/list-counts/",
};

export const TOKEN = "token";
export const LOGGEDUSER = "loggeduser";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const LOCATION = "userlocation";
export const LOCATIONNAME = "locationname";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
