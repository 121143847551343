import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const TopbarTwo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showHeader = ['/already-autopay', '/setup-autopay', '/paybycard', '/plate-not-read', '/plate-autopay-on', '/on-registry'];
    console.log(location?.pathname, "Pathname");

    return (
        <div className='topbar-top'>
            <div className='bg-gray1 p-3 border-bot text-center pt-4'>
                <h4 className='f4 fs-sm-12 fs-md-16 fs-lg-20 fs-xl-22 fs-xxl-24 mb-0'> The Revalie Ottawa Parking</h4>
                {showHeader.includes(location?.pathname) && (   
                    <div className='d-flex ac-jc'>
                        <p onClick={() => { navigate("/") }} className=' f1 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 sec border-bot mb-0 pointer'> Change LotID</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopbarTwo