import React from 'react'
import { useNavigate } from 'react-router-dom';
import { licence_icon } from '../../assets/imgs';

const SetupAutopay = () => {
    const navigate = useNavigate();
    return (
        <div className='body-center d-flex flex-column ac-jb'>
            <div>
                <div className='mt-4  d-flex ac-jc'>
                    <div className="inbox  ontario-box">
                        <p className=' f9 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 sec  mb-0'> ONTARIO</p>
                        <h4 className='f10 fs-xs-20 fs-sm-22 fs-md-24 fs-lg-26 fs-xl-28 fs-xxl-30 mb-0 letter-spacing'> ABCD123</h4>
                    </div>
                </div>
                <div className='mt-1 d-flex ac-jc'>
                    <p onClick={() => navigate("/licence-plate")} className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 pointer'> Change Plate</p>
                </div>
                <div className='d-flex ac-jc mt-4'>
                    <img src={licence_icon} className='tick-icon' alt="" />
                    <p className=' f5 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 w-70 mb-0 ms-2'> This lot uses license plate reading cameras. Setting up auto-pay will allow you to travel through our lots hands free! <span> <a href="">FAQ</a> </span> </p>
                </div>
            </div>
            <div className='d-flex ac-jc'>
                <div >
                    <button onClick={() => { navigate("/confirm-autopay") }} className='confirm-btn  f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer'>Setup Autopay</button>
                    <div className='d-flex ac-jc'>
                        <p className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec border-bot mb-0 mt-1  pointer'> Pay Manually</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetupAutopay