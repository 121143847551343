import React from 'react'
import { useNavigate } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { apple_icon, payment_icon } from '../../assets/imgs';

const ConfirmAutopay = () => {
    const navigate = useNavigate();
    return (
        <div className='body-center container d-flex flex-column ac-jb'>
            <div className='w-100'>
                <button onClick={() => navigate("/setup-autopay")} className='cust-btn  sec1 d-flex ac-js'><NavigateBeforeIcon />Back</button>
                <div className='d-flex ac-jc mt-4'>
                    <div className='col-md-6 '>
                        <h4 className=' f3 fs-sm-12 fs-md-16 fs-lg-21 fs-xl-23 fs-xxl-24 '>Setup Auto-pay</h4>
                        <div className='d-flex ac-js mt-4'>
                            <img src={payment_icon} className='card-icon' alt="" />
                            <input type="text" className='input-get ms-2  w-100' placeholder='Card Number ' />
                        </div>
                        <div className='d-flex ac-jb mt-3 '>
                            <input type="text" className='input-get col-md-3 col-sm-3' placeholder='MM/YY' />
                            <input type="text" className='input-get col-md-2 col-sm-2' placeholder='CVC' />
                            <input type="text" className='input-get col-md-4 col-sm-3' placeholder='Postal Code' />
                        </div>
                        <div className='d-flex flex-column'>
                            <input type="text" className='input-get  mt-3' placeholder='Name on Card' />
                            <input type="text" className='input-get  mt-3' placeholder='Create Password' />
                            <p className=' f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 sec mt-3 mb-0'>This password will let you log into the app/site to manage auto-pay, see parking history, and more!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex ac-jc mt-4'>
                <div className=''>
                    <div>
                        <button onClick={() => { navigate("/autopay-success") }} className='confirm-btn  f7 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer'>Confirm</button>
                    </div>
                    <p className=' f1 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 sec  text-center my-2  pointer'>or</p>
                    <div className='d-flex ac-jc '>
                        <button onClick={() => { navigate("/autopay-success") }} className='confirm-btn bg-gray3 f5 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18  px-5 mb-0 pointer d-flex ac-jc'>Add <img src={apple_icon} className='apple-icon mx-1' alt="" /> Pay</button>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default ConfirmAutopay
